
import { defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { notify } from "@/core/helpers/globalJaya";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "coop-login",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      if (route.query.state !== localStorage.getItem("socialState")) {
        localStorage.removeItem("socialState");
        notify({
          text: "Votre tentative de connexion a échoué en raison d'un problème de sécurité",
        });
      } else {
        // localStorage.removeItem("socialState");
        const payload = {
          code: route.query.code,
          referer: route.query.referer,
          social_state: route.query.session_state,
        };
        store.dispatch(Actions.COOP_LOGIN, payload).then((resp) => {
          if (resp.data.project_id) {
            router.push({
              name: "homepage",
              query: { projectId: resp.data.project_id },
            });
          } else {
            router.push({ name: "homepage" });
          }
        });
      }
    });
  },
});
